import React, { useState } from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
  Spinner,
} from "reactstrap";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "./index.css";

import PropTypes from "prop-types";

//redux
import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// import images
import logo from "../../assets/images/logo.png";

import CarouselPage from "../AuthenticationInner/CarouselPage";
import { createSelector } from "reselect";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";

const ResetPassword = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorData = createSelector(
    (state: any) => state.login,
    (state) => ({
      error: state.error,
    })
  );

  document.title = "Login | Nebras Portal";

  const [loading, setLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleForgetPassword = async (e: any) => {
    e.preventDefault();
    if (email.length <= 6) {
      setError("Please enter proper email");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api-sact.nebrascorp.com/api/auth/reset",
        { email },
        { withCredentials: true }
      );
      if (response.status === 200) {
        console.log(response.data);
        navigate("/new-password", { replace: true });
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log(e.response.data?.message);
      if (e.response.data?.message) {
        setError(e.response.data?.message);
      }
    }
  };
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="80" />{" "}
                      </Link>
                      <span className="logo-txt text-lg">
                        ZATCA E-Invoicing Soltion
                      </span>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">
                          Enter your email to receive reset password link
                        </h5>
                      </div>
                      <div
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>

                          <Input
                            required
                            name="email"
                            className="form-control"
                            placeholder="Enter Email"
                            type="email"
                            onChange={(e: any) => {
                              setEmail(e.target.value);
                            }}
                            value={email}
                          />
                        </div>
                        <Label className="text-danger">{error && error}</Label>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                onClick={handleForgetPassword}
                              >
                                {loading ? (
                                  <FaSpinner
                                    // type="Puff"
                                    // color="#00BFFF"
                                    height={230}
                                    width={230}
                                    style={{
                                      animation: "rotate360 2s linear infinite",
                                    }}
                                  />
                                ) : (
                                  // <Spinner style={{width:'24px',height:'24px'}} color='light'>Loading...</Spinner>
                                  <>Reset Now</>
                                )}
                              </button>
                            </div>

                            <br />

                            <div
                              style={{ paddingLeft: 0 }}
                              className="form-check"
                            >
                              <Link to="/login" className="form-check-label">
                                Login
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 text-center">
                        <ul className="list-inline">
                          <li className="list-inline-item"></li>
                          <li className="list-inline-item"></li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Copyright Nebras Enterprise
                        Software
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
ResetPassword.propTypes = {
  history: PropTypes.object,
};
