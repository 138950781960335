export const csr = [
  {
    _id: 'ObjectId("58e740491f8eec0004e02aef")',
    binaryToken:
      "LS0tLS1CRUdJTiBDRVJUSUZJQ0FURSBSRVFVRVNULS0tLS0NCk1JSUNCakNDQWEwQ0FRQXdjekVMTUFrR0ExVUVCaE1DVUVzeER6QU5CZ05WQkFzTUJrNWxZbkpoY3pFcU1DZ0cNCkExVUVDZ3doVG1WaWNtRnpJRVZ1ZEdWeWNISnBjMlVnVUhKcGRtRjBaU0JNYVcxcGRHVmtNU2N3SlFZRFZRUUQNCkRCNVBSMDR0TXpJMk5ETXhNVFExTFRNek16TXpNek16TWprNU1EQXdNRE13VmpBUUJnY3Foa2pPUFFJQkJnVXINCmdRUUFDZ05DQUFUZW1QVHpyMjRuc2d6emkzVW43RjFnUDY0bmhKK1pleVRVL1JDRHpPNWVLcUs3UGh2bGYrZUoNCmsvU0hsZGZkcmlBcTlmOUp2Ty9sRG5sYXgzM1c2elh6b0lIYU1JSFhCZ2txaGtpRzl3MEJDUTR4Z2Nrd2djWXcNCklRWUpLd1lCQkFHQ054UUNCQlFNRWxwQlZFTkJMVU52WkdVdFUybG5ibWx1WnpDQm9BWURWUjBSQklHWU1JR1YNCnBJR1NNSUdQTVRzd09RWURWUVFFRERJeExVOUhUbnd5TFU5SFRud3pMV1ZrTWpKbU1XUTRMV1UyWlRRdE1URXgNCk9DMDVZalU0TFdRNVlUaG1NVEZsTkRnM2FERWZNQjBHQ2dtU0pvbVQ4aXhrQVFFTUR6TTBORFEwTkRrNU9Ua3cNCk1EQTVNekVOTUFzR0ExVUVEQXdFTVRFd01ERVJNQThHQTFVRUdnd0lVbEpTUkRFNU1qa3hEVEFMQmdOVkJBOE0NCkJGUmxZMmd3Q2dZSUtvWkl6ajBFQXdJRFJ3QXdSQUlnTmpCMUtCcTB0M0tKSGpnT0pnVE1QSjhpYnc5bGFyY0kNCnViTTd0YUppWm9jQ0lFV21LcW9LSFNVamhxcklTM3Z6MisyMHRZMElKV2tvNThnNVQ2ZEp0TXVZDQotLS0tLUVORCBDRVJUSUZJQ0FURSBSRVFVRVNULS0tLS0NCg",
    secretKey:
      "MIGNAgEAMBAGByqGSM49AgEGBSuBBAAKBHYwdAIBAQQg1OfwKxW3wScqeEzl5wzI+50xncXqEOXRqZEliFxmXpOgBwYFK4EEAAqhRANCAATemPTzr24nsgzzi3Un7F1gP64nhJ+ZeyTU/RCDzO5eKqK7Phvlf+eJk/SHldfdriAq9f9JvO/lDnlax33W6zXz",
    serialNumber: "EXG-0101-01-8e38140e61b2",
    session: "",
    directory: {
      csrConfig: {
        name: "company_config.properties",
        path: "/public/csrConfig/company_config.properties/",
      },
      csr: {
        name: "company_config.properties",
        path: "/public/csr/company.csr/",
      },
      pak: {
        name: "company_pak.key",
        path: "/public/pak/company.key/",
      },
    },
    status: "",
    version: 1,
    dbtimestamp: "2022-08-31 07:14:44.752Z",
  },
  {
    _id: 'ObjectId("58e740491f8eec0004e02aef")',
    binaryToken: "LS0tLS1CRUdJTiBDRVJUSUZJ",
    secretKey: "MIGNAgEAMBAGByqGSM49AgEGBSuBBAAKBHYwdAIBAQQg1OfwKxW3w",
    serialNumber: "EXG-0101-01-8e38140e61b2",
    session: "",
    directory: {
      csrConfig: {
        name: "company_config.properties",
        path: "/public/csrConfig/company_config.properties/",
      },
      csr: {
        name: "company_config.properties",
        path: "/public/csr/company.csr/",
      },
      pak: {
        name: "company_pak.key",
        path: "/public/pak/company.key/",
      },
    },
    status: "",
    version: 1,
    dbtimestamp: "2022-08-31 07:14:44.752Z",
  },
];

export const compliance = [
  {
    _id: 'ObjectId("58e740491f8eec0004e02aeg")',
    csr: 'ObjectId("58e740491f8eec0004e02aef")',
    requestId: "1234567890123",
    binaryToken:
      "TUlJQ1REQ0NBZk9nQXdJQkFnSUdBWTVxbHlHa01Bb0dDQ3FHU000OUJBTUNNQlV4RXpBUkJnTlZCQU1NQ21WSmJuWnZhV05wYm1jd0hoY05NalF3TXpJek1Ea3hOREk0V2hjTk1qa3dNekl5TWpFd01EQXdXakNCa1RFTE1Ba0dBMVVFQmhNQ1VFc3hEekFOQmdOVkJBc01CazVsWW5KaGN6RXFNQ2dHQTFVRUNnd2hUbVZpY21GeklFVnVkR1Z5Y0hKcGMyVWdVSEpwZG1GMFpTQk1hVzFwZEdWa01VVXdRd1lEVlFRREREeFBSMDR0TXpJMk5ETXhNVFExTFRNek16TXpNek16TWprNU1EQXdNRE5QUjA0dE16STJORE14TVRRMUxUTXpNek16TXpNek1qazVNREF3TURNd1ZqQVFCZ2NxaGtqT1BRSUJCZ1VyZ1FRQUNnTkNBQVFXK3FYeWVmdVNDTUpZL3FzcTBGZFVCYitpY3JWVXlQVThseTJjMVlEZElFY3BzTUpnRHdVVHlueGs3UU9DQlRIUStCNmt5QlprdXhmTUw3N21YaWlBbzRHME1JR3hNQXdHQTFVZEV3RUIvd1FDTUFBd2dhQUdBMVVkRVFTQm1EQ0JsYVNCa2pDQmp6RTdNRGtHQTFVRUJBd3lNUzFQUjA1OE1pMVBSMDU4TXkxbFpESXlaakZrT0MxbE5tVTBMVEV4TVRndE9XSTFPQzFrT1dFNFpqRXhaVFE0TjJneEh6QWRCZ29Ka2lhSmsvSXNaQUVCREE4ek5EUTBORFE1T1RrNU1EQXdPVE14RFRBTEJnTlZCQXdNQkRFeE1EQXhFVEFQQmdOVkJCb01DRkpTVWtVeE9USTVNUTB3Q3dZRFZRUVBEQVJVWldOb01Bb0dDQ3FHU000OUJBTUNBMGNBTUVRQ0lHbmVONk9sZlV4SWRaMU1pc0FMU1dHZjRlY1lEbWtyZ2FnV1ZUdGlwdnFpQWlCcUhNOUN0dkpEVmlzMUp6S29qVThHN2syR1h1MXJ6YWROY2s1eVpKMGoxZz09",
    securityToken: "",
    secretKey: "yUEy7sZ6DZ9BFPj3EQAXUw+JovtonpZRDgdiK5tx5Fo=",
    session: "",
    status: "",
    version: "1",
    dbtimestamp: "2022-08-31 07:14:44.752Z",
  },
];

export const onboarding = [
  {
    _id: 'ObjectId("58e740491f8eec0004e02aef")',
    csid: 'ObjectId("58e740491f8eec0004e02aeg")',
    requestId: "",
    tokenType: "",
    binaryToken: "",
    secretKey: "",
    validity: "",
    session: "",
    status: "",
    version: "",
    dbtimestamp: "2022-08-31 07:14:44.752Z",
  },
];

export const companyProfile = [
  {
    _id: 'ObjectId("58e740491f8eec0004e02aef")',
    clientNo: "EXG",
    cocode: "0101",
    organizationIdentifier: "344444999900093",
    commonName: "Filter Expert Trading Company",
    name: "Filter Expert Trading Company",
    // retailsStoreNo: "",
    countryName: "SA",
    location: "",
    invoiceType: 1100,
    companyURL: "https://filtersexperts.com/",
    region: "",
    industry: "Trading",
    location: [
      {
        uuid: "bb8ecc40-3882-4472-ab71-8e38140e61b2",
        retailstoreno: "01",
        retailstorestxt: "Filters Experts Old Branch  K3",
        // serialNumber: "EXG-0101-01-8e38140e61b2",
        serialNumber: "1-EXG|2-0101|3-01-|4-8e38140e61b2",
        // serialNumber: "1-OGN|2-OGN|3-ed22f1d8-e6e4-1118-9b58-d9a8f11e487h",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "a57b2174-ebca-4e42-9ace-dc90ebc7d26b",
        retailstoreno: "02",
        retailstorestxt: "Filters Experts Jeddah K14 Branch",
        serialNumber: "EXG-0101-02-dc90ebc7d26b",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "fca53bb3-7486-4aae-91e8-3df73d5a8b7e",
        retailstoreno: "03",
        retailstorestxt: "Filter Expert Warehouse Jeddah ",
        serialNumber: "EXG-0101-03-3df73d5a8b7e",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "599b9a50-9732-4321-9ffa-bad290accbea",
        retailstoreno: "04",
        retailstorestxt: "Filters Experts Warehouse Dammam",
        serialNumber: "EXG-0101-04-bad290accbea",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "dbde6165-1c1a-415a-9bc6-cf1ca20265ed",
        retailstoreno: "05",
        retailstorestxt: "Filters Experts Dammam Branch",
        serialNumber: "EXG-0101-05-cf1ca20265ed",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "676563bf-5c0a-4fda-ae34-40daeaa13072",
        retailstoreno: "06",
        retailstorestxt: "Filters Experts Johra Branch Jeddah",
        serialNumber: "EXG-0101-06-40daeaa13072",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "f717d8aa-adb5-4af3-9bfd-2521a362f175",
        retailstoreno: "07",
        retailstorestxt: "Filters Experts Madina Branch Tabouk Road",
        serialNumber: "EXG-0101-07-2521a362f175",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "e3d99e01-4d44-4ed9-b985-b8e22bd9ce47",
        retailstoreno: "08",
        retailstorestxt: "Ali Bin Abu Talib Road",
        serialNumber: "EXG-0101-08-b8e22bd9ce47",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "1400ae52-9403-4fa5-bb44-28d2b42d8836",
        retailstoreno: "09",
        retailstorestxt: "Filters Experts Warehouse Riyadh",
        serialNumber: "EXG-0101-08-28d2b42d8836",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "07fbc682-f34f-41d6-828b-5f791d566562",
        retailstoreno: "10",
        retailstorestxt: "Filters Experts New Branch",
        serialNumber: "EXG-0101-08-5f791d566562",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "f9aa1889-c992-4d8c-91cc-776c0ad8e7fb",
        retailstoreno: "11",
        retailstorestxt: "Filters Experts Al Sulay Riyadh",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "2e404f1c-f513-43a3-93d0-374605495db6",
        retailstoreno: "12",
        retailstorestxt: "Filters Experts Bab Makkah Kilo 1",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "d91c00a9-3f58-48fa-9d5a-b4fc9216d987",
        retailstoreno: "13",
        retailstorestxt: "Filters Experts Head Office Ksa",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "ef9bb058-2928-4060-a561-d88bbd8c54f7",
        retailstoreno: "14",
        retailstorestxt: "Filters Experts Dammam Dallah Branch",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "44c9a6f9-4bb0-4ba5-9470-c6cabddfb8d9",
        retailstoreno: "15",
        retailstorestxt: "Filters Experts Khamis Mushait Branch ",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "35cc7690-7f13-4fbf-8b30-a7b0a767d9ec",
        retailstoreno: "16",
        retailstorestxt: "Filters Experts Dammam Prince Naif Road Branch",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "df3cae64-9398-4708-a8cb-4c8f004bd634",
        retailstoreno: "17",
        retailstorestxt: "Filters Experts Bassmi Branch",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "bf240134-25b9-471f-bab6-eb156406d564",
        retailstoreno: "18",
        retailstorestxt: "Filters Experts Bureada Branch",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "76380582-22d7-44f3-9fe8-6bc491ace8b3",
        retailstoreno: "19",
        retailstorestxt: "Filters Experts Jubail Branch",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
    ],
  },
  {
    _id: 'ObjectId("58e740491f8eec0004e02aef")',
    clientNo: "EXG",
    cocode: "0102",
    organizationIdentifier: "",
    commonName: "Equipment Parts Trading Company",
    name: "Equipment Parts Trading Company",
    countryName: "SA",
    location: "",
    invoiceType: 1100,
    companyURL: "https://partsexperts.net/",
    region: "",
    industry: "Trading",
    location: [
      {
        uuid: "7a605e5c-3193-4d5f-9eee-6a8b494a6a13",
        retailstoreno: "01",
        retailstorestxt: "Epe Parts Experts",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "6857471a-17c1-4b52-ba30-fc3fea3ac2bc",
        retailstoreno: "02",
        retailstorestxt: "Riyadh Ali Bin Abu Talib Road",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "83646b83-148f-476c-af3b-4544e5190a5d",
        retailstoreno: "03",
        retailstorestxt: "Epe Kilo 23 Branch",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
      {
        uuid: "a2dee029-a257-4305-91da-6f8c6a8356bb",
        retailstoreno: "04",
        retailstorestxt: "Epe Al Jawhara Branch",
        serialNumber: "",
        countryName: "SA",
        region: "",
        street: "",
      },
    ],
  },
  {
    _id: 'ObjectId("58e740491f8eec0004e02aef")',
    clientNo: "EXG",
    cocode: "0103",
    organizationIdentifier: "",
    commonName: "Lathe Expert Factory",
    name: "Lathe Expert Factory",
    countryName: "SA",
    location: "",
    invoiceType: 1100,
    companyURL: "https://technologiesexpertise.com/",
    region: "",
    industry: "Trading",
    location: {
      uuid: "",
      retailstoreno: "",
      retailstorestxt: "",
      serialNumber: "",
      countryName: "SA",
      region: "",
      street: "",
    },
  },
];

// Omer's obj
// export const InvoiceXmlModel = [
//   {
//     templateNo: "",
//     processcode: "ARDI",
//     description: "",
//     dataDictionary: [{
//         tag: "cbc:ProfileID",
//         order: "1",
//         format: "",
//         label: "Business process type",
//         mapId: "",
//         defaultValue: "reporting:1.0"
//     }, {
//         tag: "cbc:ID",
//         order: "2",
//         format: "",
//         label: "",
//         mapId: "ardocumentno",
//         defaultValue: ""
//     }, {
//         tag: "UUID",
//         order: "3",
//         format: "",
//         label: "",
//         mapId: "",
//         defaultValue: ""
//     }, {
//         tag: "IssueDate",
//         order: "4",
//         format: "",
//         label: "",
//         mapId: "postdate",
//         defaultValue: ""
//     }, {
//         tag: "IssueTime",
//         order: "5",
//         format: "",
//         label: "",
//         mapId: "",
//         defaultValue: ""

//     }, {
//         tag: "InvoiceTypeCode",
//         order: "6",
//         format: "",
//         label: "",
//         mapId: "",
//         defaultValue: 388
//     }, {
//         tag: "Note",
//         order: "7",
//         format: "",
//         label: "",
//         mapId: "documentstxt",
//         defaultValue: ""
//     }, {
//         tag: "DocumentCurrencyCode",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "currencyno",
//         defaultValue: ""
//     }, {
//         tag: "TaxCurrencyCode",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//         defaultValue: "SAR"
//     }, {
//         tag: "OrderReference",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//         defaultValue: "SAR"
//     }, {
//         tag: "BillingReference",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//         defaultValue: "SAR",
//         child: true
//     }, {
//         tag: "ContractDocumentReference",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//         defaultValue: "SAR",
//         child: true
//     }

//     ,

//     {
//         tag: "StreetName",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//     }, {
//         tag: "BuildingNumber",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//     }, {
//         tag: "CitySubdivisionName",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//     }, {
//         tag: "CityName",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//     }, {
//         tag: "PostalZone",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//     }, {
//         tag: "TaxCurrencyCode",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//     }, {
//         tag: "PaymentMeansCode",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "",
//     }, {
//         tag: "TaxAmount",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "taxdocamount",
//     }, {
//         tag: "TaxAmount",
//         order: "",
//         format: "",
//         label: "",
//         mapId: "taxdocamount",
//     }]
//   },
// ];

//Huzaifa's Objs

export const InvoiceXmlModel = [
  {
    templateNo: "",
    processcode: "ARDI",
    description: "",
    dataDictionary: [
      {
        tag: "cbc:ProfileID",
        order: "1",
        label: "Business process type",
        mapId: "",
        defaultValue: "reporting:1.0",
      },
      {
        tag: "cbc:ID",
        order: "2",
        label: "Invoice number",
        mapId: "ardocumentno",
        defaultValue: "",
      },
      {
        tag: "cbc:UUID",
        order: "3",
        label: "Unique invoice identifier",
        mapId: "",
        defaultValue: "",
      },

      // {
      //   tag: "cbc:IssueDate",
      //   order: "4",
      //   label: "Invoice issue date (gregorian calendar)",
      //   mapId: "postdate",
      //   defaultValue: "",
      // },

      // {
      //   tag: "cbc:IssueTime",
      //   order: "5",
      //   label: "Invoice issue time",
      //   mapId: "",
      //   defaultValue: "",
      // },

      // {
      //   tag: "cbc:InvoiceTypeCode",
      //   order: "6",
      //   label: "Invoice type code",
      //   mapId: "",
      //   defaultValue: "388",
      // },

      // {
      //   tag: "cbc:InvoiceTypeCode / @name",
      //   order: "7",
      //   label: "Invoice type transactions",
      //   mapId: "",
      //   defaultValue: "010100",
      // },

      // {
      //   tag: "cbc:Note",
      //   order: "8",
      //   label: "Invoice note",
      //   mapId: "documentstxt",
      //   defaultValue: "",
      // },

      // {
      //   tag: "cbc:Note",
      //   order: "8",
      //   label: "Invoice note",
      //   mapId: "documentstxt",
      //   defaultValue: "",
      // },

      // {
      //   tag: "cbc:DocumentCurrencyCode",
      //   order: "9",
      //   label: "Invoice currency code",
      //   mapId: "currencyno",
      //   defaultValue: "",
      // },

      // {
      //   tag: "cbc:TaxCurrencyCode",
      //   order: "10",
      //   label: "Tax currency code",
      //   mapId: "currencyno",
      //   defaultValue: "",
      // },

      // {
      //   tag: "cac:OrderReference/cbc:ID",
      //   order: "10",
      //   label: "Purchase order ID",
      //   mapId: "currencyno",
      //   defaultValue: "",
      // },
    ],
  },
];
