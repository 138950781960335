import React, { useState } from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
} from "reactstrap";

import PropTypes from "prop-types";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logo from "../../assets/images/logo.png";

import CarouselPage from "../AuthenticationInner/CarouselPage";
import { createSelector } from "reselect";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import Swal from "sweetalert2";

const NewPassword = (props: any) => {
  const dispatch = useDispatch();

  const errorData = createSelector(
    (state: any) => state.login,
    (state) => ({
      error: state.error,
    })
  );

  document.title = "Login | ZATCA - ZATCA by Nebras";

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleVerifyAndUpdatePassword = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (password == password2 && otp.length == 4) {
      console.log("Yes");
      return;
    }
    try {
      const response = await axios.post(
        "https://api-sact.nebrascorp.com/api/auth/verify-and-update-password",
        { otp: otp, password: password },
        { withCredentials: true }
      );
      if (response.status === 200) {
        console.log(response.data);
        navigate("/new-password", { replace: true });
      }
      Swal.fire({
        title: "Password Updated!",
        text: "Your password has been updated successfully .",
        icon: "success",
      }).then(() => navigate("/login"));
      setLoading(false);
    } catch (e: any) {
      setLoading(false);

      console.log(e.response?.data?.message);
      if (e.response.data?.message) {
        setError(e.response.data?.message);
      }
    }
  };
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="80" />{" "}
                      </Link>
                      <span className="logo-txt text-lg">
                        ZATCA E-Invoicing Soltion
                      </span>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Enter OTP and updated password</h5>
                      </div>
                      <div
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">
                            OTP (from email){" "}
                          </Label>
                          <Input
                            required
                            name="otp"
                            className="remove-number-arrows form-control"
                            placeholder="Enter OTP "
                            type="text"
                            inputMode="numeric"
                            value={otp}
                            maxLength={6}
                            onChange={(e: any) => {
                              setOtp(e.target.value);
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Label className="form-label">New Password</Label>
                            </div>
                          </div>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={password}
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={(e: any) => setPassword(e.target.value)}
                            />
                            <button
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="btn btn-light shadow-none ms-0"
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Label className="form-label">
                                Confirm New Password
                              </Label>
                            </div>
                          </div>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password-confirm"
                              value={password2}
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={(e: any) =>
                                setPassword2(e.target.value)
                              }
                            />
                            <button
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="btn btn-light shadow-none ms-0"
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                        </div>
                        <Label className="text-danger">{error && error}</Label>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                onClick={handleVerifyAndUpdatePassword}
                              >
                                {loading ? (
                                  <FaSpinner
                                    height={230}
                                    width={230}
                                    style={{
                                      animation: "rotate360 2s linear infinite",
                                    }}
                                  />
                                ) : (
                                  <>Update Password</>
                                )}
                              </button>
                            </div>

                            <br />

                            <div
                              style={{ paddingLeft: 0 }}
                              className="form-check"
                            >
                              <Link to="/login" className="form-check-label">
                                Login
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 text-center">
                        <ul className="list-inline">
                          <li className="list-inline-item"></li>
                          <li className="list-inline-item"></li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Copyright Nebras Enterprise
                        Software
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewPassword;
NewPassword.propTypes = {
  history: PropTypes.object,
};
