import { Routes, Route, Navigate } from "react-router-dom";
import Routing from "./routes/allRoutes";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
// Imports for agGrid Styles
// import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
//
import Login from "./pages/Authentication/Login";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ResetPassword from "./pages/Authentication/Reset-password";
import NewPassword from "./pages/Authentication/New-password";
import UiGeneral from "./pages/UiComponents/UiGeneral";
const App = () => {
  const navigate = useNavigate();

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/developer_zone" element={<UiGeneral />} />
      </Routes>
      <Routing />
    </>
  );
};

export default App;
