import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/secondary/user.png";

//redux
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProfileMenu = (props: any) => {
  // const { success } = useSelector((state: any) => ({
  //   success: state.profile.success,
  // }));

  // const profiledata = createSelector(
  //     success: state.success
  //   })
  // )
  // // Inside your component
  // const { success } = useSelector(profiledata)

  // Declare a new state variable, which we'll call "menu"
  // const selectUserData = (state: any) => state.login.user;
  // const userData = useSelector(state.login);

  const userData = useSelector((state: any) => state?.login?.user?.userData);

  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState(userData?.username);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        "https://api-sact.nebrascorp.com/api/auth/logout",
        {},
        {
          withCredentials: true,
        }
      );
      console.log(response.data);
      if (response.status === 200) {
        navigate("/logout");
      }
    } catch (error) {
      console.log("error from logut", error);
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            style={{ maxWidth: "50px", maxHeight: "50px" }}
            className="rounded-circle header-profile-user rounded-2"
            src={user1}
            // alt=''
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium">
            {username && username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <Link to={"/profile"} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
            {props.t("Profile")}{" "}
          </Link>{" "}
          {/* <Link to={"/contacts-profile"} className="dropdown-item">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </Link>

          <Link to="/page-lock-screen" className="dropdown-item">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </Link> */}
          <div className="dropdown-divider" />
          <div onClick={handleLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(ProfileMenu);
