import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  FirstDataRenderedEvent,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FcEmptyFilter } from "react-icons/fc";
import { FaSpinner } from "react-icons/fa";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
]);

const DetailCellRenderer = (props: any) => {
  const data = props.data;
  return (
    <Row className="bg-dark detail-row">
      <Col xs={12} className="text-right">
        <div className="detail-group">
          <div className="detail-label text-light">Response Details:</div>
          <pre style={{ whiteSpace: "pre-wrap" }} className="text-light">
            {JSON.stringify(data?.response, null, 2)}
          </pre>
        </div>
      </Col>
    </Row>
  );
};

const CertificateList = () => {
  const [loading, setLoading] = useState(false);
  const [onboardingData, setOnboardingData] = useState([]);
  const [reminder, setReminder] = useState<any>(false);

  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  const fetchCertificates = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `https://api-zatca.nebrascorp.com/api/credential`
      );
      console.log("clerance", response?.data);
      setOnboardingData(response.data.response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCertificates();
  }, []);

  console.log(onboardingData, "certificate");

  const FailureRenderer = (props: any) => {
    return <span style={{ color: "red" }}>Failure</span>;
  };
  const SuccessRenderer = (props: any) => {
    return <span style={{ color: "green" }}>Success</span>;
  };

  const containerStyle = useMemo(() => ({ width: "100%", height: "75vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "S No.", cellRenderer: "agGroupCellRenderer" },
    { field: "organization" },
    { field: "organizationIdentifier" },
    {
      field: "success",
      cellRenderer: (params: any) =>
        params.value ? <SuccessRenderer /> : <FailureRenderer />,
    },
    { field: "dbtimestamp", headerName: "Created On" },
    { field: "validity" },
  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="align-items-center">
          <Col md={6}>
            <h5 className="card-title">
              Total Certificates Invoices
              <span className="text-muted fw-normal ms-2">
                {onboardingData?.length ? onboardingData?.length : 0}
              </span>
            </h5>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "70vh" }}
                >
                  <FaSpinner
                    // type="Puff"
                    size={35}
                    color="#5156BE"
                    height={500}
                    width={500}
                    style={{
                      animation: "rotate360 2s linear infinite",
                    }}
                  />
                </div>
              ) : onboardingData?.length > 0 ? (
                <div style={containerStyle}>
                  <div style={gridStyle} className={"ag-theme-quartz"}>
                    <AgGridReact
                      rowData={onboardingData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      enableAdvancedFilter={true}
                      pagination
                      detailRowAutoHeight={true}
                      masterDetail={true}
                      detailCellRenderer={detailCellRenderer}
                      // onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "500px" }}
                >
                  <Col>
                    <Row>
                      <FcEmptyFilter size={35} />
                      <h3 className="text-muted text-center">
                        No Certificates Found
                      </h3>
                    </Row>
                  </Col>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default CertificateList;
