import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  FirstDataRenderedEvent,
  ModuleRegistry,
} from "@ag-grid-community/core";

import QRCode from "react-qr-code";


import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FcEmptyFilter } from "react-icons/fc";
import { FaSpinner } from "react-icons/fa";
import { IoEye } from "react-icons/io5";
import { SlOptionsVertical } from "react-icons/sl";
import dummyImg from '../../assets/images/dummyImg.jpg'

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { CsvExportModule } from "@ag-grid-community/csv-export";

import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
  CsvExportModule,
  ExcelExportModule,

]);


// {/* <div className="detail-group">
// {/* <div className="detail-label text-dark">Response Details:</div>
// <pre style={{ whiteSpace: "pre-wrap" }} className="text-dark">
// {JSON.stringify(data?.response, null, 2)}
// </pre>
//  */}
// </div>

const DetailCellRenderer = (props: any) => {
  const data = props.data;
  console.log(data, "dataaaaaaaaaaaaa")

  // const base64String = btoa(data?.qr);
  // if(base64String){
  // }
  // const actualBase64 = `data:image/png;base64, ${base64String}`
  // console.log(actualBase64, "base644444444")

  return (
    <Row className="detail-row ">
      <Col xs={4} className="text-right" style={{margin: "0px 0 12px 0"}}>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Invoice No:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.oldardocumentno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Reference No:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.referenceno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Retail Store No:{" "}
            </span>
            <span>{data?.invoiceObject?.retailstoreno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Customer Name:{" "}
            </span>
            <span>{data?.invoiceObject?.customername}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Customer VAT No.:{" "}
            </span>
            <span>{data?.invoiceObject?.registrationno}</span>
          </div>
         
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Address:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.address}</span>
          </div>

          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Created User:{" "}
            </span>
            <span>{data?.invoiceObject?.creuser}</span>
          </div>
        </div>
      </Col>
      <Col xs={4} className="text-right"  style={{margin: "0px 0 12px 0"}}>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
           <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Currency:{" "}
            </span>
            <span>{data?.invoiceObject?.currencyno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Payment Method:{" "}
            </span>
            <span>{data?.invoiceObject?.paymentmethodno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Gross Doc Amount:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.grossdocamount.toFixed(2)}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
             Discount Amount:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.discountdocamount.toFixed(2)}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Net Doc Amount:{" "}
            </span>
            <span>{data?.invoiceObject?.netdocamount.toFixed(2)}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Tax Doc Amount:{" "}
            </span>
            <span>{data?.invoiceObject?.taxdocamount.toFixed(2)}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Doc Amount Including Tax:{" "}
            </span>
            <span>{data?.invoiceObject?.docamountincludingtax.toFixed(2)}</span>
          </div>
        
        </div>
      </Col>
      <Col  xs={3} className="text-right" style={{margin: "0px 0 12px 0", }}>
        {data?.success
        ?
        <QRCode
          size={256}
          style={{ height: "175px",  width: "175px", float: "right" }}
          value={data?.qr}
          viewBox={`0 0 256 256`}
        /> 
        :
       <div>
        <h5>Response</h5>
        <p style={{overflowY: 'scroll',width: 'fit-content' , fontSize: "0.8rem" , backgroundColor: "#ff0000c0", padding: "16px", borderRadius: "08px" , color: "white", fontWeight: "800"}}>
          {data?.response}
        </p>

       </div>
        }

      </Col>
    </Row>
  );
};

const ClearanceList = () => {
  const [loading, setLoading] = useState(false);
  const [clearanceData, setClearance] = useState([]);
  const [reminder, setReminder] = useState<any>(false);
  const gridRef = useRef<AgGridReact<any>>(null);

  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  const fetchClearance = async () => {
    setLoading(true);
    try {
      const response= await axios.get(
        `https://api-zatca.nebrascorp.com/api/clearance`
        // `http://192.168.100.216:3003/api/clearance`
      );
      console.log("clerance", response?.data);
      setClearance(response.data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchClearance();
  }, []);

  const FailureRenderer = (props: any) => {
    return <span style={{ color: "red" }}>Failure</span>;
  };
  const SuccessRenderer = (props: any) => {
    return <span style={{ color: "green" }}>Success</span>;
  };

  console.log(clearanceData, "clearnceData");


  const containerStyle = useMemo(() => ({ width: "100%", height: "75vh",  }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" , }), []);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "", cellRenderer: "agGroupCellRenderer", maxWidth: 60 },

    { field: "id", headerName: "S No.", maxWidth: 80, 
      valueGetter: (params:any) => params.node.rowIndex + 1, // Display row index starting from 1
    },
    {
      field: "ardocumentno",
      headerName: "Reported Invoice No.",
      maxWidth: 200,
    },
    { field: "oldardocumentno", headerName: "Invoice No.", maxWidth: 200 },
    { field: "transactionlayerno", headerName: "Invoice Type", maxWidth: 150 },

    {
      field: "invoiceObject.customername",
      headerName: "Customer Name",
      maxWidth: 150,
    },
    {
      field: "invoiceObject.grossdocamount",
      headerName: "Gross Total",
      maxWidth: 150,
    },

    {
      field: "invoiceObject.postdate",
      headerName: "Posting Date",
      maxWidth: 300,
    },
    { field: "createdAt", headerName: "Submission Date", maxWidth: 300 },
    {
      field: "success",
      headerName: "Status",
      maxWidth: 130,
      cellRenderer: (params: any) =>
        params.value ? <SuccessRenderer /> : <FailureRenderer />,
    },

    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 130,
      cellRenderer: (params: any) => (
        <>
          <IoEye style={{ marginRight: "12px" }} /> <SlOptionsVertical />
        </>
      ),
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // flex: 1,
      // maxWidth: 200,
      filter: true,
      // floatingFilter: true,
      resizable: true

    };
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.api.forEachNode(function (node) {
      node.setExpanded(node.id === "1");
    });
  }, []);


  const onBtExport = useCallback(() => {
    gridRef?.current!.api.exportDataAsExcel();
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        {/* 
        <h5 className="card-title">
          Total Cleared Invoices
          <span className="text-muted fw-normal ms-2">
            {clearanceData?.length ? clearanceData?.length : 0}
          </span>
        </h5> */}

        <div className="page-title-box d-sm-flex align-items-center justify-content-between" style={{paddingBottom: 0}}>
          <h2 className="mb-0 font-size-24">Clearance  <span className='font-size-12' style={{display: "block"}}>These are the simplified invoices generated through this system</span></h2>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <a href="/Invoices">Invoices</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                <span>Clearance</span>
              </li>
            </ol>
          </div>
        </div>

        <Row>
          <Col lg="12">
            <div>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "70vh" }}
                >
                  <FaSpinner
                    // type="Puff"
                    size={35}
                    color="#5156BE"
                    height={500}
                    width={500}
                    style={{
                      animation: "rotate360 2s linear infinite",
                    }}
                  />
                </div>
              ) : clearanceData?.length > 0 ? (
                <div style={containerStyle}>

                  <div style={{textAlign: "right"}}>
                    <button onClick={onBtExport} style={{ margin: "5px 0" ,fontWeight: "500" , border :"0", background: "#72C9D2", borderRadius: "8px", padding: "4px 12px", color: "white"}}>Export to Excel</button>
                  </div>

                  <div
                    style={gridStyle}
                    className={"ag-theme-quartz myCustomGridStyles"}
                  >
                    
                    <AgGridReact
                      ref={gridRef}
                      rowData={clearanceData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      // enableAdvancedFilter
                      pagination
                      paginationPageSize={500}
                      paginationPageSizeSelector={false}
                      masterDetail={true}
                      detailRowAutoHeight={true}
                      detailCellRenderer={detailCellRenderer}
                      // onFirstDataRendered={onFirstDataRendered}
                      defaultExcelExportParams={{fileName: 'clearance_invoices'}}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "500px" }}
                >
                  <Col>
                    <Row>
                      <FcEmptyFilter size={35} />
                      <h3 className="text-muted text-center">
                        No Clearance Found
                      </h3>
                    </Row>
                  </Col>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ClearanceList;
