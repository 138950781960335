import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  Spinner,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import axios from "axios";
import Swal from "sweetalert2";

const UploadDocument = (props: any) => {
  const [formData, setFormData] = useState<any>("");
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<Boolean>(false);

  document.title = "ZATCA E-Invoicing By Nebras";

  const handleFormData = async (e: any) => {
    e.stopPropagation();
    let { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    console.log("form data", formData);
  };

  const handleUploadDoc = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    console.log("sending this one ", formData);
    try {
      const response = await axios.post(
        "https://api-zatca.nebrascorp.com/api/generate-xml",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);

      if ((response.status = 200 && response.data)) {
        Swal.fire({
          title: "Data Uploaded!",
          text: `${response?.data}`,
          icon: "success",
        });
      }
      console.log(response.data);
      setData(response.data);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 403) {
        // console.log(error?.response);
        // Swal.fire({
        //   title: "Already Exist!",
        //   text: `${error?.response?.data?.message}`,
        //   icon: "warning",
        // });
      }
      console.log(error);
    }
  };

  // useEffect(() => {
  // }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Upoad Data" breadcrumbItem="Upload Xlsx File " />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <div className="d-flex align-items-start">
                      <div className="flex-grow-1">
                        <div className="mb-2">
                          {/* <img src={logo} alt="" height="24" /> */}
                          <span className="logo-txt">
                            Upload Document File In XLSX Format
                          </span>
                          <br />
                          <span
                            style={{
                              marginLeft: "5px",
                              marginTop: "0px",
                              marginBottom: "0px",
                            }}
                          >
                            Browse or Drop File to upload &nbsp;
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="mb-4">
                          <Link to="/clearance">
                            <FeatherIcon
                              icon="x-circle"
                              className="text-muted hover-red"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />

                  <Form onSubmit={handleUploadDoc}>
                    <Row>
                      <div className=" d-flex justify-content-center">
                        {/* col1 */}
                        <Col sm="3">
                          <div className="border-bottom   p-2">
                            <span>Select File To Upload</span>
                            <Input
                              accept=".xlsx"
                              type="file"
                              name="file"
                              onChange={handleFormData}
                              required
                            ></Input>
                            <br />
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <hr />
                    {!loading ? (
                      <>
                        {/* <Row>
                          <div className=" d-flex justify-content-center  ">
                            <Col sm="6">
                              <div>
                                <Label
                                  htmlFor="outcome"
                                  style={{ width: "100%" }}
                                >
                                  <span className="font-size-17">
                                    Generated CSR
                                  </span>
                                </Label>{" "}
                                <div
                                  className="form-control"
                                  style={{
                                    width: "90%",
                                    marginTop: "12px",
                                    minHeight: "200px",
                                  }}
                                  placeholder="Enter updated outcome..."
                                  id="outcome"
                                >
                                  <span className="text-muted font-size-10">
                                    {data?.csr}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Row> */}
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col style={{ minHeight: "200px" }} sm="12">
                            <div className=" w-100   align-center text-center">
                              <Spinner
                                color="primary"
                                style={{
                                  margin: "auto",
                                  height: "3rem",
                                  width: "3rem",
                                }}
                                type="grow"
                              ></Spinner>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        <Button type="submit" className="btn btn-success">
                          Upload Document
                        </Button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UploadDocument;
