import React, { useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";


import ClearanceList from "./clearanceList";

const Clearance = (search: any) => {
  document.title = "Clearance | ZATCA by Nebras";

  return (
    <React.Fragment>
      <div className="page-content px-0">
        <Container fluid>
          <ClearanceList />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Clearance;
