import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => {
  return (
    <React.Fragment>
      <footer className='footer'>
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Nebras</Col>
            <Col md={6}>
              <div className='text-sm-end d-none d  -sm-block'>
                Design & Develop by
                <Link
                  to='https://nebrascorp-uat.vercel.app'
                  className='ms-1 text-decoration-underline'
                >
                  Nebras Software Development Pvt Ltd
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
