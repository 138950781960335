// import "ag-grid-enterprise";
// import "ag-grid-community";
// import "@ag-grid-community/styles/ag-grid.css";
// import "@ag-grid-community/styles/ag-theme-quartz.css";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { companyProfile } from "src/dummyData";
// Ag
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  ColGroupDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModuleRegistry,
  createGrid,
} from "@ag-grid-community/core";
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
// import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
// import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
// import { MenuModule } from "@ag-grid-enterprise/menu";
// import DetailCellRenderer from "./detailCellRenderer";
import { CompanyProfile } from "./interfaces";
import Swal from "sweetalert2";
// ModuleRegistry.registerModules([
//   ClientSideRowModelModule,
//   ColumnsToolPanelModule,
//   MasterDetailModule,
//   MenuModule,
// ]);

const CSRGenerator = (props: any) => {
  const [formData, setFormData] = useState<any>();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<Boolean>(false);
  const [open, setOpen] = useState<any>(false);
  const [companies, setCompanies] = useState([]);

  const toggleLeftCanvas = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();
  document.title = "ZATCA E-Invoicing By Nebras";
  // Inside your component

  const DetailCellRenderer = (val: any) => {
    const data = val.data;
    console.log("clicked", data);
    return (
      <Row className="detail-row">
        <Col xs={6} className="detail-col">
          <div className="detail-group">
            <div className="detail-label">Common Name:</div>
            <div className="detail-value">{data.commonName}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Client No:</div>
            <div className="detail-value">{data.clientNo}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Organization Identifier:</div>
            <div className="detail-value">{data.organizationIdentifier}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Country Name:</div>
            <div className="detail-value">{data.countryName}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Industry:</div>
            <div className="detail-value">{data.industry}</div>
          </div>
        </Col>
        <Col xs={4} className="detail-col">
          <div className="detail-group">
            <div className="detail-label">Invoice Type:</div>
            <div className="detail-value">{data.invoiceType}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Company URL:</div>
            <div className="detail-value">{data.companyURL}</div>
          </div>
        </Col>
        <Col xs={2}>
          <Link to="#" className="btn btn-success" onClick={toggleLeftCanvas}>
            Generate CSR
          </Link>
        </Col>
      </Row>
    );
  };

  const handleFormData = async (e: any) => {
    e.stopPropagation();
    let { name, value, type, files } = e.target;
    console.log("simple value", value);

    if (name === "data") {
      setFormData({
        ...formData,
        [name]: JSON.parse(value),
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    console.log("form data", formData);
    // console.log("form data parse", JSON.parse(value));
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        "https://api-zatca.nebrascorp.com/api/organizations"
      );
      // console.log("organization response", response.data?.response);
      setCompanies(response.data?.response);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log("hardoceded companies", companyProfile);
    console.log("api companies", companies);
  }, [companies]);

  const handleGenerateCSR = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const data = formData?.data;
    console.log("sending this data to generate csr ", formData?.data);
    const mappedData = {
      "csr.common.name": data["csr.common.name"] || data.commonName,
      "csr.serial.number": data.location[0].serialNumber,
      "csr.organization.identifier": data.organizationIdentifier,
      "csr.organization.unit.name": data.name,
      "csr.organization.name": data.name,
      "csr.country.name": data.countryName,
      "csr.invoice.type": Number(data.invoiceType),
      "csr.location.address": data.location,
      "csr.industry.business.category": data.industry,
    };
    console.log(mappedData);
    try {
      const response = await axios.post(
        "https://api-zatca.nebrascorp.com/api/csr/generate",
        mappedData
      );
      setLoading(false);

      console.log("response is here", response.data);
      setData(response.data);
      if (response.status === 200) {
        Swal.fire({
          title: " Certificate Signing request ",
          text: `CSR Generated `,
          icon: "success",
        });
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "500px" }),
    []
  );

  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<any[]>(companyProfile);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "S. No.", cellRenderer: "agGroupCellRenderer" },
    { field: "_id" },
    { field: "clientNo" },
    { field: "cocode" },
    { field: "organizationIdentifier" },
    { field: "commonName", width: 200 },
    { field: "name" },
    { field: "countryName" },
    { field: "locations" },
    { field: "invoiceType" },
    { field: "companyURL" },
    { field: "region" },
    { field: "industry" },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
    };
  }, []);
  const gridOptionsStyles = {
    defaultColDef: {
      width: 120,
    },
  };

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.api.forEachNode(function (node) {
      node.setExpanded(node.id === "1");
    });
  }, []);

  const visibleColumns = [
    "S. No.",
    // "_id",
    "clientNo",
    "cocode",
    // "organizationIdentifier",
    "commonName",
    // "name",
    "countryName",
    // "locations",
    "invoiceType",
    "companyURL",
    // "region",
    // "industry",
  ];

  const filteredColumnDefs = columnDefs.filter((columnDef: any) => {
    return visibleColumns.includes(columnDef.field);
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="CSR"
            breadcrumbItem="Generate Certificate Signing Request"
          />

          <div style={containerStyle}>
            <div style={gridStyle} className={"ag-theme-quartz"}>
              {companies.length >= 0 ? (
                <AgGridReact<CompanyProfile>
                  rowData={companies}
                  masterDetail={true}
                  detailCellRenderer={(params: any) =>
                    detailCellRenderer(params)
                  }
                  columnDefs={filteredColumnDefs}
                  // columnDefs={columnDefs}
                  gridOptions={gridOptionsStyles}
                  defaultColDef={defaultColDef}
                  onFirstDataRendered={onFirstDataRendered}
                />
              ) : (
                "No Organization added in database"
              )}
            </div>
          </div>
        </Container>
      </div>

      <Offcanvas
        isOpen={open}
        toggle={toggleLeftCanvas}
        direction="end"
        style={{ minWidth: "40%" }}
      >
        <OffcanvasHeader toggle={toggleLeftCanvas}>
          ADD CSR Details
        </OffcanvasHeader>
        <OffcanvasBody
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Form onSubmit={handleGenerateCSR} style={{ minWidth: "80%" }}>
            {/* <FormGroup floating>
              <Input
                type="text"
                name="csr.common.name"
                onChange={handleFormData}
                id="csr.common.name"
                required
              ></Input>
              <Label for="">Common Name</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                type="text"
                name="csr.serial.number"
                onChange={handleFormData}
                id="csr.serial.number"
                required
              ></Input>
              <Label for="csr.serial.number">Serial Number</Label>
            </FormGroup>{" "} */}
            <FormGroup floating>
              <Input
                type="select"
                required
                name="data"
                onChange={handleFormData}
              >
                <option value="">Select Company </option>

                {companies?.map((item: any, index: any) => {
                  // console.log("simple", item, "shahzaib", companyProfile[item]);
                  return (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.organizationIdentifier}
                    </option>
                  );
                })}
              </Input>
              <Label for="csr.organization.identifier">
                Organization Identifier
              </Label>
            </FormGroup>{" "}
            {/* <FormGroup floating>
              <Input
                required
                type="text"
                name="csr.organization.unit.name"
                id="csr.organization.unit.name"
                onChange={handleFormData}
              ></Input>
              <Label for="csr.organization.identifier">
                Organizaion Unit Name
              </Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                type="text"
                required
                name="csr.organization.name"
                id="csr.organization.name"
                onChange={handleFormData}
              ></Input>
              <Label for="csr.organization.identifier">Organization Name</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                type="text"
                required
                name="csr.country.name"
                id="csr.country.name"
                onChange={(e: any) => {
                  handleFormData(e);
                }}
              ></Input>
              <Label for="csr.country.name">Country Name</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                type="text"
                required
                name="csr.invoice.type"
                onChange={(e: any) => {
                  handleFormData(e);
                }}
              ></Input>
              <Label for="csr.invoice.type">Invoice Type</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                type="text"
                required
                name="csr.location.address"
                id="csr.location.address"
                onChange={(e: any) => {
                  handleFormData(e);
                }}
              ></Input>
              <Label for="csr.location.address">Location Address</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                type="text"
                required
                name="csr.industry.business.category"
                id="csr.industry.business.category"
                onChange={(e: any) => {
                  handleFormData(e);
                }}
              ></Input>

              <Label for="csr.industry.business.category">
                Industry Business Category
              </Label>
            </FormGroup>{" "} */}
            <Button className="btn btn-success" type="submit">
              Generate
            </Button>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default CSRGenerator;
