import { LoginTypes } from './actionTypes'

const initialState = {
  user: null,
  error: '',
  loading: false
}

const login = (state = initialState, action: any) => {
  switch (action.type) {

    case LoginTypes.SAVE_USER_DATA:
    state = {
      ...state,
      user: action.payload,
    };
    break;

    case LoginTypes.LOGIN_USER:
      state = {
        ...state,
        loading: true
      }
      break
    case LoginTypes.LOGIN_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        loading: false
      }
      break
    case LoginTypes.LOGOUT_USER:
      state = { ...state, user: null } // Clear user on logout
      break
    case LoginTypes.LOGOUT_USER_SUCCESS:
      state = { ...state, user: null }
      break
    case LoginTypes.API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
