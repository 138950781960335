import React, { useEffect, useState } from "react";

import {
  Input,
  FormFeedback,
  Label,
  Alert,
  Container,
  Row,
  Col,
  Form,
} from "reactstrap";

import PropTypes from "prop-types";

//redux
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// import images
import logo from "../../assets/images/logo.png";

//Import config
import CarouselPage from "../AuthenticationInner/CarouselPage";

import axios from "axios";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { saveUserData } from "src/store/actions";

const Login = (props: any) => {
  const [errorMessage, setError] = useState<any>(false);
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();
  const handleLogin = async (e: any) => {
    navigate("/clearance");
  };
  // navigateToPage()

  useEffect(() => {
    localStorage.removeItem("expireOn");
  }, []);

  const handleChange = (e: any) => {
    e.preventDefault();
    const { name, value, type, files } = e.target;
  };

  document.title = "Login | Nebras Portal";

  const [passwordShow, setPasswordShow] = useState(false);

  return (
    <React.Fragment>
      {contextHolder}
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block  auth-logo">
                        <img src={logo} alt="" height="120" />{" "}
                      </Link>
                      {/* <span className="logo-txt text-sm">
                        ZATCA E-Invoicing Soltion
                      </span> */}
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome To ZATCA !</h5>
                        <p className="text-muted mt-2">Sign in to continue.</p>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={handleLogin}
                      >
                        {errorMessage ? (
                          <Alert color="danger">{errorMessage}</Alert>
                        ) : null}
                        <div className="mb-3">
                          <Label className="form-label">Username</Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter Username"
                            // value={form["username"]}
                            type="text"
                          />
                        </div>

                        <div className="mb-3">
                          {/* <Label className="form-label">Password</Label> */}
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Label className="form-label">Password</Label>
                            </div>
                          </div>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              // value={""}
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter Password"
                            />
                            <button
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="btn btn-light shadow-none ms-0"
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                onClick={handleLogin}
                              >
                                Log In
                              </button>
                            </div>

                            <br />
                            <div
                              style={{ paddingLeft: 0 }}
                              className=" form-check"
                            >
                              <Link
                                to="/reset-password"
                                className=" form-check-label"
                              >
                                Forget Password
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Form>

                      <div className="mt-4 text-center">
                        <ul className="list-inline">
                          <li className="list-inline-item"></li>
                          <li className="list-inline-item"></li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Copyright Nebras Enterprise
                        Software
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
Login.propTypes = {
  history: PropTypes.object,
};
